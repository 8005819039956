const img = '';

export default [
  {
    img: img,
    title: 'Dec 01<sup>st</sup>, 2021 - Fitness Connection',
    description: `Beby joins the gym, and fate pairs her with Martin as her personal trainer. Attraction sparks between them from the very beginning and they can’t resist texting each other, sharing more than just “gym-hacks” or the “healthy diet 101”.`,
  },
  {
    img: img,
    title: 'Dec 26<sup>th</sup>, 2021 - Feast of Love',
    description: `First date at Hachi Grill for an All-You-Can-Eat Competition. Martin wins the competition easily, yet he lets Beby thinks she is the winner. What a memorable first date!`,
  },
  {
    img: img,
    title: 'Mar 13<sup>th</sup>, 2022 - A Birthday to Remember',
    description: `Martin decides to formalize their relationship on Beby’s birthday, a meaningful step towards a deeper commitment.
    `,
  },
  {
    img: img,
    title: 'Dec 24<sup>th</sup>, 2022 - Christmas Eve Proposal',
    description: `Martin pops the question, answered with a 100% YES from Beby. A magical moment that adds more enchantment to the holiday season.
     `,
  },
  // {
  //   img: img,
  //   title: '20 AUGUST 2023',
  //   description: `The Proposal  <br />
  //   On our 5th anniversary dinner, Hendry got down on one knee
  //   and popped the question "Will you marry me?"  <br />
  //   Karma Kandara, Bali 
  //    `,
  // },
];
