import React, { useState } from 'react';
import { string } from 'prop-types';
import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Select,
  Heading,
  useToast,
  Text,
  MenuButton,
  Menu,
  InputGroup,
  Icon,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';

import WithAnimation from '@components/Common/WithAnimation';

import { txtForm } from './locales';
import { HEADING_STYLES } from '@constants/colors';

import { useGuest } from '@/context/guest';
import useFormFields from '@hooks/useFormFields';
import useRsvp from '@/usecase/use-rsvp';

import { txtTitle } from './locales';
import {
  ENABLE_GUEST_PERSONALIZATION,
  RSVP_ENABLE_NAME,
  RSVP_ENABLE_PHONE_NUMBER,
  RSVP_ENABLE_ADDRESS,
  RSVP_ENABLE_EMAIL,
  ENABLE_QR_INVITATION,
  RSVP_ENABLE_ATTEND_INFO,
  ENABLE_SHIFT_TIME_RECEPTION,
} from '@constants/feature-flags';
import {
  ERROR_TYPE,
  FORM_LABEL_PROPS,
  INPUT_COMMON_PROPS,
  SELECT_COMMON_PROPS,
  TYPE,
} from './types';
import { RSVP_STATUS } from '@constants/api';
import useAddNewGuest from '@/usecase/use-add-new-guest';
import { COUNTRY_CODES } from '@constants/countryCodes';
import { BiChevronDown } from 'react-icons/bi';
import { API_ATTEND_INFO } from '@/constants/api';

/**
 * function to render RSVP component
 * @returns {JSX.Element}
 * @author idindrakusuma
 */
function RSVPSection({ lang, ...rest }) {
  const toast = useToast();
  const { guest, onUpdateGuest, isRegistered } = useGuest();

  const { onAddNewGuest } = useAddNewGuest();
  const { onUpdateRsvp } = useRsvp();

  const filteredEventInfo = API_ATTEND_INFO.filter((item) =>
    item.shift.includes(RSVP_ENABLE_ATTEND_INFO && ENABLE_SHIFT_TIME_RECEPTION ? guest.shift : ''),
  );

  const [isLoading, setIsLoading] = useState(false);
  const [errorType, setErrorType] = useState(ERROR_TYPE);
  const { formFields, createChangeHandler } = useFormFields({
    name: isRegistered ? guest.name : '',
    phone_number: guest.phone_number || '',
    guest_quota: guest.guest_quota,
    total_guest: guest.total_guest || 1,
    address: guest.address,
    email: guest.email,
    is_attended: guest.status === 'NOT_ATTEND' ? TYPE.NO : TYPE.YES,
    attend_info: RSVP_ENABLE_ATTEND_INFO ? guest.attend_info || filteredEventInfo[0]?.value : '',
  });

  const [selectedCountryCode, setSelectedCountryCode] = useState(guest.country_code || '62');

  const handleCountryCodeChange = (code) => {
    setSelectedCountryCode(code);
  };

  const callToasterError = (desc, title = 'Oops!') => {
    toast({
      title,
      description: desc,
      status: 'error',
      isClosable: true,
    });
  };

  const callToasterSuccess = (desc, title = txtForm.success[lang]) => {
    toast({
      title,
      description: desc,
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };

  /**
   * function to submit to BE with check the form value first
   */

  const onSubmitForm = async () => {
    const {
      name,
      address,
      phone_number,
      total_guest,
      is_attended,
      email,
      attend_info,
    } = formFields; // reset error state

    setErrorType(ERROR_TYPE);
    // define current error state
    let currentErrorType = ERROR_TYPE;

    // Make sure user already input the value
    /**
     * ERROR CHECKING
     */
    if (RSVP_ENABLE_NAME && !name) {
      currentErrorType = { ...currentErrorType, name: txtForm.required[lang] };
    }

    if (RSVP_ENABLE_PHONE_NUMBER) {
      if (!phone_number) {
        currentErrorType = { ...currentErrorType, phone: txtForm.required[lang] };
      } else if (phone_number.length > 13 || phone_number.length < 5) {
        currentErrorType = { ...currentErrorType, phone: txtForm.invalidPhone[lang] };
      }
    }

    if (RSVP_ENABLE_ADDRESS && !address) {
      currentErrorType = { ...currentErrorType, address: txtForm.required[lang] };
    }

    if (RSVP_ENABLE_EMAIL) {
      if (!email) {
        currentErrorType = { ...currentErrorType, email: txtForm.required[lang] };
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        currentErrorType = { ...currentErrorType, email: txtForm.invalidEmail[lang] };
      }
    }

    if (
      currentErrorType.address ||
      currentErrorType.email ||
      currentErrorType.name ||
      currentErrorType.phone
    ) {
      setErrorType(currentErrorType);
      return;
    }
    setIsLoading(true);
    const guestPayload = {
      name,
      address: address || '-',
      countryCode: selectedCountryCode,
      phoneNumber: phone_number || '-',
      email: email || '-',
      shift: guest.shift || '-',
      attendInfo: is_attended === TYPE.YES ? attend_info : '',
      system_info: 'WARNING_Terdapat Perubahan dari User_',
    };

    // Usecase: Group or General Invitation
    // We will `insert` instead of `update` if user doens't have code yet. But,
    // we will throw error if QR Invitation
    if (!guest.code) {
      if (ENABLE_QR_INVITATION) {
        setIsLoading(false);
        callToasterError(txtForm.msgError[lang]);
        return;
      }
      await onAddNewGuest({
        ...guestPayload,
        desc: guest.name || '-',
        guest_quota: guest.guest_quota,
        guest_confirm: total_guest && is_attended === TYPE.YES ? total_guest : 0,

        status: is_attended === TYPE.YES ? RSVP_STATUS.attend : RSVP_STATUS.notAttend,
        onSuccess: (guestCode) => {
          setIsLoading(false);
          callToasterSuccess(txtForm.msgSuccess[lang]);
          onUpdateGuest(guestCode);
        },
        onFailed: () => {
          setIsLoading(false);
          callToasterError(txtForm.msgError[lang]);
        },
      });
      return;
    }
    await onUpdateRsvp({
      ...guestPayload,
      desc: guest.desc || '-',
      isAttended: is_attended === TYPE.YES,
      totalGuest: total_guest && is_attended === TYPE.YES ? total_guest : 0,

      onSuccess: () => {
        setIsLoading(false);
        // onResetFormWithKey('phone_number');
        callToasterSuccess(txtForm.msgSuccess[lang]);
      },
      onFailed: () => {
        setIsLoading(false);
        callToasterError(txtForm.msgError[lang]);
      },
    });
  };

  return (
    <Box padding="0 0 16px 0" bgColor="bgPrimary" {...rest}>
      <Box bgColor="bgPrimary">
        <Box padding="8px">
          <WithAnimation>
            <Box padding="24px" width="100%">
              {/* Title & Desc Section */}
              <Box textAlign="center" color="mainColorText">
                <Heading {...HEADING_STYLES} paddingLeft="0px" textAlign="center" marginTop="16px">
                  RSVP
                </Heading>
                <Text fontSize="md" margin="16px 0 24px 0">
                  {txtTitle[lang]}
                </Text>
              </Box>

              {/* Form Sections - Name */}
              {RSVP_ENABLE_NAME && (
                <FormControl margin="12px 0 8px 0" isInvalid={errorType.name}>
                  <FormLabel {...FORM_LABEL_PROPS}>{txtForm.name[lang]}:</FormLabel>
                  <FormLabel fontStyle={'italic'}>*) {txtForm.nameHelper[lang]}</FormLabel>
                  <Input
                    {...INPUT_COMMON_PROPS}
                    placeholder="..."
                    value={formFields.name}
                    onChange={createChangeHandler('name')}
                    disabled={isRegistered}
                  />
                  <FormErrorMessage marginTop="4px">{errorType.name}</FormErrorMessage>
                </FormControl>
              )}

              {/* Form Sections - Phone Number */}
              {RSVP_ENABLE_PHONE_NUMBER && (
                <FormControl margin="16px 0" isInvalid={errorType.phone}>
                  <FormLabel {...FORM_LABEL_PROPS}>{txtForm.hp[lang]}:</FormLabel>
                  <InputGroup size="sm">
                    <Menu>
                      <MenuButton
                        {...SELECT_COMMON_PROPS}
                        as={Button}
                        rightIcon={<Icon as={BiChevronDown} />}
                      >
                        {`+${selectedCountryCode}`}
                      </MenuButton>
                      <MenuList maxHeight="200px" overflowY="scroll" zIndex={3}>
                        {COUNTRY_CODES.map((country) => (
                          <MenuItem
                            key={country.dial_code}
                            onClick={() => handleCountryCodeChange(country.dial_code)}
                          >
                            {`${country.name}: +${country.dial_code}`}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Menu>
                    <Input
                      {...INPUT_COMMON_PROPS}
                      placeholder="..."
                      type="number"
                      value={formFields.phone_number}
                      onChange={createChangeHandler('phone_number')}
                    />
                  </InputGroup>
                  <FormErrorMessage marginTop="4px">{errorType.phone}</FormErrorMessage>
                </FormControl>
              )}

              {/* Form Sections - Address */}
              {RSVP_ENABLE_ADDRESS && (
                <FormControl margin="8px 0" isInvalid={errorType.address}>
                  <FormLabel {...FORM_LABEL_PROPS}>{txtForm.address[lang]}:</FormLabel>
                  <Input
                    {...INPUT_COMMON_PROPS}
                    placeholder="..."
                    value={formFields.address}
                    onChange={createChangeHandler('address')}
                  />
                  <FormErrorMessage marginTop="4px">{errorType.address}</FormErrorMessage>
                </FormControl>
              )}

              {/* Form Sections - Email */}
              {RSVP_ENABLE_EMAIL && (
                <FormControl margin="8px 0" isInvalid={errorType.email}>
                  <FormLabel {...FORM_LABEL_PROPS}>Email:</FormLabel>
                  <Input
                    {...INPUT_COMMON_PROPS}
                    placeholder="..."
                    value={formFields.email}
                    onChange={createChangeHandler('email')}
                  />
                  <FormErrorMessage marginTop="4px">{errorType.email}</FormErrorMessage>
                </FormControl>
              )}

              {/* Form Sections - Attendance */}
              <FormControl margin="8px 0">
                <FormLabel {...FORM_LABEL_PROPS}>{txtForm.willYoutAttend[lang]}</FormLabel>
                <Select
                  {...SELECT_COMMON_PROPS}
                  value={formFields.is_attended}
                  onChange={createChangeHandler('is_attended')}
                >
                  <option value={TYPE.YES} style={{ color: 'black' }}>
                    {txtForm.willAttend[lang]}
                  </option>
                  <option value={TYPE.NO} style={{ color: 'black' }}>
                    {txtForm.noAttend[lang]}
                  </option>
                </Select>
              </FormControl>

              {/* Form Section - Type of Event */}
              {RSVP_ENABLE_ATTEND_INFO && formFields.is_attended === TYPE.YES && (
                <FormControl>
                  <FormLabel {...FORM_LABEL_PROPS}>Which event will you attend?</FormLabel>
                  <Select
                    {...SELECT_COMMON_PROPS}
                    value={formFields.attend_info}
                    onChange={createChangeHandler('attend_info')}
                  >
                    {filteredEventInfo.map((item) => {
                      return (
                        <option key={item.value} value={item.value} style={{ color: 'black' }}>
                          {item.title}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
              )}

              {/* Form Sections - Partner */}
              {formFields.is_attended === TYPE.YES && (
                <>
                  <FormControl>
                    <FormLabel {...FORM_LABEL_PROPS}>{txtForm.willYouBringPartner[lang]}</FormLabel>
                    <Select
                      {...SELECT_COMMON_PROPS}
                      value={formFields.total_guest}
                      onChange={createChangeHandler('total_guest')}
                    >
                      {ENABLE_GUEST_PERSONALIZATION ? (
                        <>
                          {guest.guest_quota >= 0 && (
                            <option value={1} style={{ color: 'black' }}>
                              1
                            </option>
                          )}
                          {guest.guest_quota > 1 && (
                            <option value={2} style={{ color: 'black' }}>
                              2
                            </option>
                          )}
                          {guest.guest_quota > 2 && (
                            <option value={3} style={{ color: 'black' }}>
                              3
                            </option>
                          )}
                          {guest.guest_quota > 3 && (
                            <option value={4} style={{ color: 'black' }}>
                              4
                            </option>
                          )}
                          {guest.guest_quota > 4 && (
                            <option value={5} style={{ color: 'black' }}>
                              5
                            </option>
                          )}
                        </>
                      ) : (
                        <>
                          <option value={1} style={{ color: 'black' }}>
                            1
                          </option>
                          <option value={2} style={{ color: 'black' }}>
                            2
                          </option>
                        </>
                      )}
                    </Select>
                    {false && (
                      <FormHelperText color="mainColorText" fontSize="10px" fontStyle="italic">
                        {txtForm.singleGuestInfo[lang]}
                      </FormHelperText>
                    )}
                  </FormControl>
                </>
              )}
              <Center>
                <Button
                  isFullWidth
                  color="secondaryColorText"
                  fontWeight="light"
                  colorScheme="green"
                  isLoading={isLoading}
                  marginTop="24px"
                  size="md"
                  type="button"
                  bgColor="bgSecondary"
                  onClick={onSubmitForm}
                  textAlign="left"
                  fontFamily="body"
                  justifyContent="left"
                  borderRadius="0"
                >
                  {txtForm.submit[lang]}
                </Button>
              </Center>
            </Box>
          </WithAnimation>
        </Box>
      </Box>
    </Box>
  );
}

RSVPSection.propTypes = {
  lang: string,
};

RSVPSection.defaultProps = {
  lang: 'id',
};

export default React.memo(RSVPSection);