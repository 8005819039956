import dayjs from 'dayjs';
import useSWR from 'swr';
import useMutation from '@/hooks/useMutation';

import { fetcher } from '@/helpers/fetcher';
import {
  API_HOSTNAME as API,
  API_TABLE_TYPE,
  ACTION_GET_TYPE,
  ACTION_POST_TYPE,
} from '@/constants/api';
import normalizeWishes from './normalizer';
import { useGuest } from '@/context/guest';

/**
 * @function useWishes
 * @description custom hook that return wishes data
 */
function useWishes() {
  // CUSTOM HOOK FOR QUERY
  const { guest } = useGuest();
  const { data, error, isLoading, mutate } = useSWR(
    API + `?action=${ACTION_GET_TYPE.read}&table_name=${API_TABLE_TYPE.wishes}`,
    fetcher,
  );

  // CUSTOM HOOK FOR MUTATION
  const { onMutation, loading } = useMutation(API_TABLE_TYPE.wishes, ACTION_POST_TYPE.insertWish);

  /**
   * @function handlePostWish
   * @description a function handler to post wish from guest
   */
  const handlePostWish = async ({ name, wish, onError, onSuccess }) => {
    const options =
      `&name=${name}` +
      `${guest.code ? `&guest_code=${guest.code}` : '&guest_code=Announcement'}` +
      `&wish=${encodeURIComponent(wish)}` +
      `&time=${encodeURIComponent(dayjs(new Date()).format('DD MMM YYYY | hh:mm:ss A'))}`;

    const result = await onMutation(options);

    if (result?.success) {
      onSuccess();
    } else {
      onError();
    }
  };

  return {
    error,
    isLoading,
    isMutating: loading,
    data: normalizeWishes(data?.data),
    onRefetch: mutate,
    onPostWish: handlePostWish,
  };
}

export default useWishes;
